<template>
  <div id="regular-list">
    <!-- regi;ar total card -->
    <v-row class="mb-5">
      <v-col v-for="total in regularTotalLocal" :key="total.title" cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ resolveRegularStatusesTitle(total.title) }}</span>
            </div>

            <v-avatar
              :color="resolveRegularTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveRegularTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveRegularTotalIcon(total.title).color" class="rounded-0">
                {{ resolveRegularTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Vouchers </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- company filter -->
        <v-col cols="12" md="4">
          <v-select
            v-model="instituteFilter"
            placeholder="Instituto"
            :items="$store.state['app-regular'].institutes"
            item-text="title"
            item-value="value"
            @change="fetchRegulars"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
        <!-- status filter -->
        <v-col cols="12" md="4">
          <v-select
            v-model="statusFilter"
            placeholder="Estado"
            :items="$store.state['app-regular'].statuses"
            item-text="title"
            item-value="value"
            @change="fetchRegulars"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
        <!-- role filter -->
        <v-col cols="12" md="4">
          <v-select
            v-model="modalityFilter"
            placeholder="Modalidad"
            :items="$store.state['app-regular'].modalityOptions"
            item-text="title"
            item-value="value"
            @change="fetchRegulars"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <companies-selection
            v-model="companyFilter"
            @change="fetchRegulars"
            outlined
            dense
            class="mb-6"
          ></companies-selection>
        </v-col>
        <v-col cols="12" md="4">
          <users-selection
            v-model="studentFilter"
            @input="fetchRegulars()"
            :usersList="usersList"
            label="Estudiante"
            outlined
            dense
          ></users-selection>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Buscar"
          outlined
          hide-details
          dense
          class="group-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" :to="{ name: 'apps-regular-view' }">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Voucher</span>
          </v-btn>

          <v-btn color="secondary" outlined class="mb-4">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="regularListTable"
        :options.sync="options"
        :footer-props="{
          'items-per-page-text': 'Vouchers por página: ',
          'items-per-page-options': [15, 30, 50, 100],
        }"
        :server-items-length="totalRegularListTable"
        :loading="loading"
        loading-text="Cargando vouchers..."
      >
        <!-- reference -->
        <template #[`item.reference`]="{ item }">
          <div class="d-flex align-center">
            <router-link
              :to="{ name: 'apps-regular-view', params: { id: item._id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <span class="text-capitalize">{{ item.reference }}</span>
            </router-link>
          </div>
        </template>

        <!-- student -->
        <template #[`item.student`]="{ item }">
          <div class="d-flex align-center">
            <router-link
              :to="{ name: 'apps-user-view', params: { id: item.student } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <span>{{ item.studentData.name }} {{ item.studentData.lastname }}</span>
            </router-link>
          </div>
        </template>

        <!-- company -->
        <template #[`item.company`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ item.companyData ? `${item.companyData.fantasyName} - ${item.companyData.name}` : '' }}</span>
          </div>
        </template>

        <!-- modality -->
        <template #[`item.modality`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ resolveRegularModalityTitle(item.modality) }}</span>
          </div>
        </template>

        <template #[`item.status`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ resolveRegularStatusesTitle(item.status) }}</span>
          </div>
        </template>

        <!-- Course End -->
        <template #[`item.courseEnd`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ item.end ? date(item.end).format('DD/MM/YYYY') : '' }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-regular-view', params: { id: item._id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Detalle</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="sendEnrollment(item._id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiEmailSend }}
                  </v-icon>
                  <span>Enviar a sucursal</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="deleteItem(item._id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Eliminar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay regulares para mostrar </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import CompaniesSelection from '@/views/components/CompaniesSelection.vue'
import UsersSelection from '@/views/components/UsersSelection.vue'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiEmailSend
} from '@mdi/js'
import { onUnmounted, ref } from 'vue'

import regularStoreModule from '../regularStoreModule'
import useRegularsList from './useRegularsList'

export default {
  components: {
    CompaniesSelection,
    UsersSelection,
  },
  setup() {
    const appRegular = 'app-regular'

    // Register module
    if (!store.hasModule(appRegular)) store.registerModule(appRegular, regularStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appRegular)) store.unregisterModule(appRegular)
    })

    const {
      regularListTable,
      tableColumns,
      searchQuery,
      totalRegularListTable,
      loading,
      options,
      regularTotalLocal,
      instituteFilter,
      modalityFilter,
      statusFilter,
      companyFilter,
      studentFilter,
      date,
      usersList,
      sendEnrollment,
      fetchRegulars,
      deleteRegular,
      resolveRegularTotalIcon,
      resolveRegularModalityTitle,
      resolveRegularStatusesTitle,
    } = useRegularsList()

    const deleteItem = async item => {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: '¿Esta seguro que desea borrarlo?',
        message: 'No podra recuperarlo',
      })
      if (didConfirm) {
        deleteRegular(item)
      }
    }

    return {
      sendEnrollment,
      instituteFilter,
      modalityFilter,
      statusFilter,
      companyFilter,
      studentFilter,
      deleteItem,
      regularListTable,
      tableColumns,
      searchQuery,
      totalRegularListTable,
      loading,
      options,
      regularTotalLocal,
      usersList,
      resolveRegularTotalIcon,
      fetchRegulars,
      resolveRegularModalityTitle,
      resolveRegularStatusesTitle,
      date,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEmailSend,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/group.scss';
</style>
